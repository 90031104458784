import { Injectable, ViewContainerRef } from '@angular/core';
import { LocalesService, DialogService, DialogSingleSelectionListComponent, SingletoneStrictClass, TurboApiCallerService } from 'turbogui-angular';
import { CustomerDetailsComponent } from '../view/components/customer-details/customer-details.component';


/**
 * Service related to customer operations
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerService extends SingletoneStrictClass{

    
    constructor(private readonly ls: LocalesService,
                private readonly dialogService: DialogService,
                private readonly apiService: TurboApiCallerService) {

        super(CustomerService);
    }
    
    
    /**
     * Show a dialog to search for a customer from a list containing all that are related to the currently loged user
     * Customers are filtered by the current user at the backend
     * 
     * @param viewContainerRef This is used to attach the dialog to the correct view. If set to undefined, the dialog will be attached to the root view.
     * @param responseCallback A callback function that will receive the selected customer code and name as parameters.
     */ 
    selectCustomer(viewContainerRef:ViewContainerRef|undefined, responseCallback: (codigo: string, nombre: string) => void) {

        this.apiService.call('customers/customers-list').then(response => {
            
            this.dialogService.addDialog(
                DialogSingleSelectionListComponent,
                {
                    width:'800px',
                    modal: false,
                    texts: [this.ls.t('SELECT_A_CUSTOMER', 'turbodepot/shopping'), '', this.ls.t('SEARCH', 'turbodepot/user-interface')],
                    options: response.map((a:any) => a.codigo + ' - ' + a.nombre),
                    viewContainerRef: viewContainerRef      
                },
                (selection:any) =>{
                
                    if(selection.index >= 0){
                    
                        responseCallback(response[selection.index].codigo, response[selection.index].nombre);
                    
                    }else{
                        
                        responseCallback('', '');
                    }
                }); 
        });
    }
    

    /**
     * Show a window with information regarding the details of a specific customer
     */
    showCustomerDetails(viewContainerRef:ViewContainerRef|undefined, customerCodigo:string, responseCallback: (selection:any) => void){

        this.dialogService.addDialog(CustomerDetailsComponent, {
            width: '800px',
            data: { customerCodigo: customerCodigo },
            viewContainerRef: viewContainerRef
        }, selection =>{

            responseCallback(selection);
        });
    }
}
