<h3>{{
    expansionRoutesViewService.currentRouteName + ' - ' + ls.t('EXPEDITIONS', 'centroalum/app')
    }}
    
    <img id="addExpeditionButton" src="./assets/icons/add.svg"
        matTooltip="{{ls.t('CREATE_NEW_EXPEDITION', 'centroalum/app')}}"
        (click)="createNewExpedition()"/>
</h3>

<div class="expeditionItemContainer" *ngFor="let item of expansionRoutesViewService.expeditionsList; let i = index">
    
    <img id="editExpeditionButton_{{i}}" src="./assets/icons/edit.svg"
            matTooltip="{{ls.t('EDIT_EXPEDITION', 'centroalum/app')}}"
            (click)="editExpedition(item)"/>
    
    <h4 (click)="setExpeditionAsFilter(item)">
     
        {{item.name}}
        
    </h4>
    
    <img id="deleteExpeditionButton_{{i}}" src="./assets/icons/delete.svg"
            matTooltip="{{ls.t('DELETE_EXPEDITION', 'centroalum/app')}}"
            (click)="deleteExpedition(item)"/>

</div>