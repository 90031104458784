import { Injectable } from '@angular/core';
import { TurboApiCallerService, DialogService, ViewService, LocalesService } from 'turbogui-angular';
import { CustomerService } from '../../../controller/customer.service';


/**
 * The service for the incidences view
 */
@Injectable()
export class IncidencesViewService extends ViewService {

    
    /**
     * The customer code that is selected for incidence creation
     */
    currentCustomer = '';
   
   
    /**
     * The customer name that is selected for incidence creation
     */
    currentCustomerNombre = '';
    
    
    constructor(public ls: LocalesService,
                public dialogService: DialogService,
                public customerService: CustomerService,
                public apiService: TurboApiCallerService) {
    
        super();
    }
    
    
    createNewOrderLine(){
    }
    
}