import { Component, ViewContainerRef } from '@angular/core';
import { View, LocalesService, DialogService, TurboApiCallerService, ButtonContainerComponent, DialogTwoOptionComponent } from 'turbogui-angular';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { RouteEditComponent } from './route-edit.component';
import { ExpeditionsListComponent } from './expeditions-list.component';
import { ExpeditionsGlobalListComponent } from './expeditions-global-list.component';
import { UserModel } from 'src/main/model/models/user.model';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * expansion view
 */
@Component({
    selector: 'expansion-routes-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent, MatTooltipModule, RouterOutlet],
    providers: [ExpansionRoutesViewService],
    animations: [ContentFadeAnimationClass.getTrigger()],
    templateUrl: './expansion-routes-view.component.html',
    styleUrls: ['./expansion-routes-view.component.scss']
})


export class ExpansionRoutesViewComponent extends View {


    constructor(public ls: LocalesService,
                public route: ActivatedRoute,
                public router: Router,
                public dialogService: DialogService,
                public apiService: TurboApiCallerService,
                public userModel: UserModel,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, expansionRoutesViewService);
    }
    
    
    createNewRoute(){
        
        this.expansionRoutesViewService.startCreatingNewRoute();
            
        this.dialogService.addDialog(
            RouteEditComponent,
            {
                width: '800px',
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    editRoute(){
    
        this.expansionRoutesViewService.startEditingRoute();
        
        this.dialogService.addDialog(
            RouteEditComponent,
            {
                width: '800px',
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    showExpeditionsGlobalList(){
        
        this.dialogService.addDialog(
            ExpeditionsGlobalListComponent,
            {
                width: '96vw',
                maxWidth: '96vw',
                modal: false,
                texts: [],
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    showExpeditions(){
        
        this.expansionRoutesViewService.loadExpeditions();
                
        this.dialogService.addDialog(
            ExpeditionsListComponent,
            {
                width: '700px',
                modal: false,
                texts: [],
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    selectDateFilterDate(){
        
        this.dialogService.addDateSelectionDialog({
                id: 'dateFilterDialog',
                width: '500px',
                title: this.ls.t('FILTER_BY_ESTIMATE_DATE', 'centroalum/app'),
                viewContainerRef: this.viewContainerRef
            }, (selectedDate) => {
        
            if(selectedDate !== null){
                
                let year = selectedDate.getFullYear();
                let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
                let day = selectedDate.getDate().toString().padStart(2, '0');
                 
                this.expansionRoutesViewService.routeDetailsDateFilter = `${year}/${month}/${day}`;
                this.expansionRoutesViewService.loadRouteData();
            }
        });
    }
    
    
    deleteRoute(){
        
        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.t('DELETE_ROUTE', 'centroalum/app') + '?', this.ls.t('CONFIRM_DELETE_ROUTE', 'centroalum/app', [this.expansionRoutesViewService.currentRouteName])],
                options: [this.ls.t('DELETE_ROUTE', 'centroalum/app'), this.ls.t('CANCEL', 'turbodepot/user-interface')]
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    this.apiService.call('expansion/route-delete',
                        {routeId: this.expansionRoutesViewService.routeData.routeObject.dbId})
                        .then(response => {
                                
                            if(response === true){
                                
                                this.router.navigate([`/${AppRoutes.ordersExpansionRoutes}`]);
                            }
                        });
                }
            });
    }
    
    backToRoutesList(){

        this.router.navigate([`/${AppRoutes.ordersExpansionRoutes}`]);
    }
}