import { Component, HostBinding } from '@angular/core';
import { FadeAnimationClass, DialogService, DialogSingleOptionComponent, BrowserService, TurboApiCallerService, LocalesService } from 'turbogui-angular';
import { UserModel } from 'src/main/model/models/user.model';
import { Customer } from 'src/main/model/dtos/Customer';
import { NewUserComponent } from './new-user.component';


/**
 * Login component that is always shown when no user is loged
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [FadeAnimationClass.getTrigger('loginFade', '2s ease', '600ms ease')],
  styleUrls: ['./login.component.scss']
})


export class LoginComponent {


    constructor(public ls: LocalesService,
                public apiService: TurboApiCallerService,
                public dialogService: DialogService,
                public browserService: BrowserService,
                public userModel:UserModel) {
    }


    /**
     * This is used to attach the fade animation directly to this component so it fades in and out when created and removed from the app
     */
    @HostBinding('@loginFade') loginFade = true;


    /**
     * Execute the login operation
     */
    login() {

        this.apiService.login().then(response => {
            
            this.userModel.isCustomer = false;
            this.userModel.customer = new Customer();
            
            if(response.customer !== null){
                
                this.userModel.isCustomer = true;
                this.userModel.customer = response.customer;
            }
            
            this.userModel.relatedComercial = response.relatedComercial;
        
        }).catch(() => {
                
            this.dialogService.addDialog(
                DialogSingleOptionComponent,
                {
                    width:'400px',
                    texts: [this.ls.t('ACCESS_DENIED', 'turbodepot/users')],
                    options: [this.ls.t('CLOSE', 'turbodepot/user-interface')]
                });
        });
    }
    
    
    /**
     * Show the new user dialog
     */
    createNewUser() {

        this.dialogService.addDialog(
            NewUserComponent,
            {
                width:'95vw',
                maxWidth:'800px'
            });
    }
}
