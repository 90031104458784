import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeAnimationClass, View, LocalesService, BrowserService, TurboGuiAngularModule, TurboApiCallerService, ButtonContainerComponent } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { IncidencesViewService } from 'src/main/view/views/incidences-view/incidences-view.service';
import { SelectCustomerButtonComponent } from '../../components/select-customer-button/select-customer-button.component';
import { UserModel } from 'src/main/model/models/user.model';


/**
 * incidences view
 */
@Component({
    selector: 'app-incidences-view',
    standalone: true,
    imports: [CommonModule, SelectCustomerButtonComponent, ButtonContainerComponent, TurboGuiAngularModule],
    providers: [IncidencesViewService],
    templateUrl: './incidences-view.component.html',
    animations: [FadeAnimationClass.getTrigger('fadeIn'), ContentFadeAnimationClass.getTrigger()],
    styleUrls: ['./incidences-view.component.scss']
})


export class IncidencesViewComponent extends View implements AfterViewInit {

    
    @ViewChild(SelectCustomerButtonComponent) selectCustomerButton: SelectCustomerButtonComponent;
      

    constructor(public ls: LocalesService,
                public userModel: UserModel,
                public incidencesViewService: IncidencesViewService,
                public browserService: BrowserService,
                public apiService: TurboApiCallerService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, incidencesViewService);
    }
    
    
    ngAfterViewInit() {

        this.selectCustomerButton.onClick();
    }
    
    
    onCustomerCleared(){
        
    }
    
    
    onCustomerSelected(event:any){
        
        alert(event.customer.codigo + '-----' + event.customer.nombre);
        
        this.incidencesViewService.currentCustomer = event.customer.codigo;
        this.incidencesViewService.currentCustomerNombre = event.customer.nombre;
    }
}
