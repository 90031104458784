import { Component, ViewContainerRef } from '@angular/core';
import { View, LocalesService } from 'turbogui-angular';


/**
 * Products view
 */
@Component({
      selector: 'app-products-view',
    standalone: true,
    imports: [],
      templateUrl: './products-view.component.html',
      animations: [],
      styleUrls: ['./products-view.component.scss']
})


export class ProductsViewComponent extends View {


    constructor(public ls: LocalesService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, null);
    }
}
