<mat-sidenav-container [@mediumFade]
    *ngIf="apiService.isLogged">

    <mat-sidenav id="sidenav" #sidenav>
        
        <div class="header">
        
            <tg-button-image class="sidenavClose"
                src="./assets/icons/arrow-back-white.svg"
                [defaultOpacity]=".3"
                [hoverOpacity]=".5"
                [clickOpacity]="1"
                [clickScale]=".9"
                (click)="sidenav.toggle()">
            </tg-button-image>
            
            <img id="sidenavLogo" class="sidenavLogo"
                 src="./assets/icons/centroalum-logo.svg"
                 (click)="sidenav.toggle(); openHomeRoute()" />
            
            <h1 (click)="sidenav.toggle(); openHomeRoute()">
                Centroalum
            </h1>
            
        </div>
        
        <p id="productsMenuButton"
           [class.navMenuSelected]="isProductsSelected()"
           (click)="sidenav.toggle(); openProductsRoute()">
                    
            {{ls.t('PRODUCTS', 'turbodepot/shopping')}}
            
        </p>
        
        <p id="catalogsMenuButton"
           [class.navMenuSelected]="isCatalogsSelected()"
           (click)="sidenav.toggle(); openCatalogsRoute()">
                    
            {{ls.t('CATALOGS', 'turbodepot/shopping')}}
            
        </p>
        
        <p id="ordersMenuButton"
           [class.navMenuSelected]="isOrdersSelected()"
           *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS)"
           (click)="sidenav.toggle(); openOrdersMenuRoute()">
          
            {{ls.t('ORDERS', 'turbodepot/shopping')}}
          
        </p>
        
        <p id="configuratorMenuButton"
           *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR)"
           (click)="sidenav.toggle(); browserService.goToUrl('https://centroalum.uniapp.no/', true)">
          
            {{ls.t('CONFIGURATOR', 'turbodepot/user-interface')}}
          
        </p>
        
        <p id="reportMenuButton"
           (click)="sidenav.toggle(); browserService.goToUrl('https://transmitancia.unitest.site/', true)">
          
            {{ls.t('REPORT', 'turbodepot/shopping')}}
          
        </p>
        
    </mat-sidenav>
    
    <mat-sidenav-content>
    
        <div class="topBar">

<!--TODO - aixo ha de nar vinculat al router --> 
<!--            <h2 class="mainTitle">{{appModel.mainTitle}}</h2>-->
        
            <tg-button-image id="mainMenuButton" class="menuButton"
                src="./assets/icons/hamburguer-menu.svg"
                [defaultOpacity]=".6"
                [hoverOpacity]=".7"
                [clickOpacity]="1"
                [clickScale]=".9"
                (mousedown)="sidenav.toggle()">
            </tg-button-image>
            
            <img id="topLogoBig" class="topLogoBig"
                src="./assets/icons/centroalum-logo2.svg"
                routerLink="/" />
                
            <img class="topLogoSmall" src="./assets/icons/centroalum-logo.svg" />
            
            <div class="bigMenu">
                <hr>
                <div>                    
                    <h2 (click)="openProductsRoute()" [class.bigMenuSelected]="isProductsSelected()">
                        {{ls.tAllUpperCase('PRODUCTS', 'turbodepot/shopping')}}
                    </h2>
                    <h2 (click)="openCatalogsRoute()" [class.bigMenuSelected]="isCatalogsSelected()">
                        {{ls.tAllUpperCase('CATALOGS', 'turbodepot/shopping')}}
                    </h2>
                    <h2 *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS)"
                        [class.bigMenuSelected]="isOrdersSelected()"
                        (click)="openOrdersMenuRoute()">
                        {{ls.tAllUpperCase('ORDERS', 'turbodepot/shopping')}}
                    </h2>
                    <h2 *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR)"
                        (click)="browserService.goToUrl('https://centroalum.uniapp.no/', true)">
                        {{ls.tAllUpperCase('CONFIGURATOR', 'turbodepot/user-interface')}}
                    </h2>
                    <h2 (click)="browserService.goToUrl('https://transmitancia.unitest.site/', true)">
                        {{ls.tAllUpperCase('REPORT', 'turbodepot/shopping')}}
                    </h2>
                    <h2 (click)="logout()">
                        {{ls.tAllUpperCase('QUIT', 'turbodepot/user-interface')}}
                    </h2>
                </div>
            </div>
                        
            <tg-button-image id="logoutButton" class="logoutButton"
                src="./assets/icons/logout.svg"
                [defaultOpacity]=".3"
                [hoverOpacity]=".6"
                [clickOpacity]="1"
                [clickScale]=".7"
                (mousedown)="logout()">
            </tg-button-image>
            
        </div>
        
        <div id="viewsContainer">
            
            <router-outlet></router-outlet>
            
        </div>
        
    </mat-sidenav-content>
    
</mat-sidenav-container>

<app-login *ngIf="!apiService.isLogged">
</app-login>