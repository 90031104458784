<h2>
    <img id="routesBackButton" src="./assets/icons/arrow-back-black.svg"
        *ngIf="expansionRoutesViewService.isShowingRouteData"
        (click)="backToRoutesList()"/>
        
    <img id="showExpeditionsGlobalListButton" src="./assets/icons/earth.svg"
        matTooltip="{{ls.t('SHOW_EXPEDITIONS_GLOBAL_LIST', 'centroalum/app')}}"
        *ngIf="!expansionRoutesViewService.isShowingRouteData && apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_EXPANSION_ROUTES_GLOBAL_LIST)"
        (click)="showExpeditionsGlobalList()"/>
            
    {{ls.t('EXPANSION', 'centroalum/app')}}
    
    <img id="showExpeditionsButton" src="./assets/icons/truck.svg"
            matTooltip="{{ls.t('SHOW_EXPEDITIONS', 'centroalum/app')}}"
            *ngIf="expansionRoutesViewService.isShowingRouteData"
            (click)="showExpeditions()"/>
            
    <img id="editRouteButton" src="./assets/icons/edit.svg"
        matTooltip="{{ls.t('EDIT_ROUTE', 'centroalum/app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteData"
        (click)="editRoute()"/>
        
    <img id="dateFilterButton" src="./assets/icons/calendar.svg"
        matTooltip="{{this.ls.t('FILTER_BY_ESTIMATE_DATE', 'centroalum/app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteData"
        (click)="selectDateFilterDate()"/>
            
    <img id="deleteRouteButton" src="./assets/icons/delete.svg"
        matTooltip="{{ls.t('DELETE_ROUTE', 'centroalum/app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteData"
        (click)="deleteRoute()"/>
     
    <img id="addRouteButton" src="./assets/icons/add.svg"
        matTooltip="{{ls.t('CREATE_NEW_ROUTE', 'centroalum/app')}}"
        *ngIf="!expansionRoutesViewService.isShowingRouteData"
        (click)="createNewRoute()"/>
    
</h2>

<router-outlet [@contentFade]></router-outlet>