<div class="mainElementsContainer">

    <app-select-customer-button id="selectCustomerButton"
        [enabled]="!userModel.isCustomer"
        [viewContainerRef]="this.viewContainerRef"
        (customerCleared)="onCustomerCleared()"
        (customerConfirmed)="onCustomerConfirmed($event)" />    
</div>

<div class="linesContainer" [@fadeIn]
    *ngIf="ordersNewOrderViewService.order.customer.codigo !== ''">
    
    <h3 class="linesTitle">{{ls.t('ORDER_LINES', 'turbodepot/shopping')}}</h3>

    <div class="linesColumnsHeader" *ngIf="ordersNewOrderViewService.order.orderLines.length > 0">
        <h5>{{ls.t('AMOUNT', 'turbodepot/shopping')}}</h5>
        <h5>{{ls.t('UNIT_PRICE', 'turbodepot/shopping')}}</h5>
        <h5>{{ls.t('REFERENCE', 'turbodepot/shopping')}}</h5>
        <h5 id="linesColumnsHeaderDescription">{{ls.t('DESCRIPTION', 'turbodepot/shopping')}}</h5>
        <h5>{{ls.t('FINISH', 'turbodepot/shopping')}}</h5>
        <h5>{{ls.t('MEASURE_UNIT', 'centroalum/app')}}</h5>
        <h5>{{ls.t('TOTAL', 'turbodepot/shopping')}}</h5>
    </div>

    <div class="linesSubContainer" *ngIf="ordersNewOrderViewService.order.orderLines.length > 0">
    
        <div class="lineContainer"
            *ngFor="let line of ordersNewOrderViewService.order.orderLines; let i = index">
        
            <div class="unitsContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.units}}</h6>
            </div>
            
            <div class="unitPrice" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{ordersNewOrderViewService.calculateLineUnitPrice(line)}}€</h6>
            </div>
            
            <div class="productCodeContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.product.codigo}}</h6>
            </div>
            
            <div class="productDescriptionContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.product.descripcion}}</h6>
            </div>
            
            <div class="acabadoContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.variant.variante}}</h6>
            </div>
            
            <div  class="unidadesMedidaContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.unidadesMedida.code}}</h6>
            </div>
            
            <div class="linePriceTotal" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{ordersNewOrderViewService.calculateLineTotalPrice(line).toFixed(2)}}€</h6>
            </div>
            
            <tg-button-container id="deleteOrderLine"
                [defaultOpacity]=".7"
                [hoverOpacity]=".8"
                [clickOpacity]="1"
                [clickScale]="1.07"
                (click)="deleteOrderLine(i, line.units + ' ' + line.unidadesMedida.code + ' - ' + line.product.descripcion)">
                
                <img src="./assets/icons/delete.svg" />
        
            </tg-button-container>  
            
        </div>

    </div>
    
    <div class="newOrderLineContainer">
    
        <tg-button-container id="addNewOrderLine"
            [defaultOpacity]=".7"
            [hoverOpacity]=".8"
            [clickOpacity]="1"
            [clickScale]="1.07"
            (click)="createNewOrderLine()">
            
            <img src="./assets/icons/add.svg" />
    
        </tg-button-container>
        
        <tg-button-container id="loadLocalFile"
            [defaultOpacity]=".7"
            [hoverOpacity]=".8"
            [clickOpacity]="1"
            [clickScale]="1.07"
            (click)="loadLocalFile()">
            
            <img src="./assets/icons/upload.svg" />
    
        </tg-button-container>
        
    </div>
            
</div>
    
<tg-button-container id="submitOrderButton"
    [defaultOpacity]="1"
    [hoverOpacity]="1"
    [clickOpacity]="1"
    [clickScale]="1.07"
    *ngIf="ordersNewOrderViewService.order.orderLines.length > 0"
    (click)="ordersNewOrderViewService.sendCurrentOrder()">
    
    <img src="./assets/icons/done.svg" />
    
    <h2>{{ls.t('SEND_ORDER', 'turbodepot/shopping') + ' (' + ls.t('TOTAL', 'turbodepot/shopping') + ': ' + ordersNewOrderViewService.order.total + '€)'}}</h2>
    
</tg-button-container>  