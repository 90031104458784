import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { LocalesService, DialogBaseComponent, DialogService, TurboGuiAngularModule } from 'turbogui-angular';
import { MatDialogRef } from '@angular/material/dialog';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';


/**
 * Component that submits the current order to server
 */
@Component({
      selector: 'app-submit-order',
      standalone: true,
    imports: [CommonModule, TurboGuiAngularModule, FormsModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatSelectModule, MatCheckboxModule],
    providers: [],
      templateUrl: './submit-order.component.html',
      styleUrls: ['./submit-order.component.scss']
})


export class SubmitOrderComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "SubmitOrderComponent";
    

    constructor(public elementRef: ElementRef,
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                public dialogService: DialogService,
                public viewContainerRef: ViewContainerRef) {
                    
        super(elementRef, dialogRef);
    }
    
    
    selectShippingDate(){
        
        this.dialogService.addDateSelectionDialog({
                id: 'selectShippingDateDialog',
                width: '500px',
                title: this.ls.t('SELECT_SHIPPING_DATE', 'turbodepot/shopping'),
                viewContainerRef: this.viewContainerRef
            }, (selectedDate) => {
        
            if(selectedDate !== null){
                
                let year = selectedDate.getFullYear();
                let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
                let day = selectedDate.getDate().toString().padStart(2, '0');
                
                this.ordersNewOrderViewService.order.shipmentDate = `${year}/${month}/${day}`;        
            }
        });
    }
}
