import { Component, Inject, ElementRef } from '@angular/core';
import { LocalesService, DialogBaseComponent } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Accesorio } from 'src/main/model/dtos/Accesorio';
import { ObjectUtils } from 'turbocommons-ts';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';


/**
 * Login component that is always shown when no user is loged
 */
@Component({
      selector: 'app-accesories-edit',
      standalone: true,
    imports: [CommonModule, FormsModule, MatInputModule, MatButtonModule],
    providers: [],
      templateUrl: './accesories-edit.component.html',
      styleUrls: ['./accesories-edit.component.scss']
})


export class AccesoriesEditComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "AccesoriesEditComponent";
    

    accesories:Accesorio[] = []; 
    
    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
        
        for(let accesory of this.ordersNewOrderViewService.orderLineBeingEdited.accesories){
        
            this.accesories.push(ObjectUtils.clone(accesory));   
        }
    }
    
    
    saveAccesories(){
        
        this.ordersNewOrderViewService.orderLineBeingEdited.accesories = this.accesories;
        
        this.closeDialog(1);
    }
}
