<h3>
    {{ls.t('ROUTE_DETAILS', 'centroalum/app') + ': ' + expansionRoutesViewService.currentRouteName}}
</h3>

<p id="clearDateFilterButton"
    *ngIf="expansionRoutesViewService.routeDetailsDateFilter !== ''"
    (click)="clearDateFilter()">
    {{this.ls.t('FILTERED_BY_ESTIMATE_DATE', 'centroalum/app') + ': ' + expansionRoutesViewService.routeDetailsDateFilter}}
    
</p>

<p id="clearExpeditionFilterButton"
    *ngIf="expansionRoutesViewService.routeDetailsExpeditionFilter !== null"
    (click)="clearExpeditionFilter()">
    {{this.ls.t('FILTERED_BY_EXPEDITION', 'centroalum/app') + ': ' + ((expansionRoutesViewService.routeDetailsExpeditionFilter !== null) ? expansionRoutesViewService.routeDetailsExpeditionFilter.name : '')}}
    
</p>

<div class="headerContainer">
    <h4>{{ls.t('ORDER_NUMBER', 'turbodepot/shopping')}}</h4>
    <h4>{{ls.t('ORDER_DATE', 'turbodepot/shopping')}}</h4>
    <h4>{{ls.t('DELIVERY_DATE', 'centroalum/app')}}</h4>
    <h4 class="hideOnMobile">{{ls.t('ESTIMATE_DATE', 'centroalum/app')}}</h4>
    <h4 class="hideOnMobile">{{ls.t('OBSERVATIONS', 'turbodepot/user-interface')}}</h4>
</div>

<div class="tableContainer"
    *ngFor="let customerData of expansionRoutesViewService.routeData?.orders | keyvalue">
    
    <h5>{{ls.t('CUSTOMER', 'turbodepot/shopping') + ': ' + customerData.key + ' - ' + customerData.value.name}}</h5>
    
    <tg-button-container *ngFor="let item of customerData.value.orders; let i = index"
        id="ordersListItem_{{i}}"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".8"
        [hoverOpacity]="1"
        [clickScale]="1.04"
        [class.hasPendingToReceive]="item[4] === true"
        (click)="expansionRoutesViewService.loadOrderData(item[0], customerData.key, customerData.value.name, item[6])">
        
        <p>{{item[0]}}</p>
        <p>{{item[1]}}</p>
        <p>{{item[2]}}</p>
        <p class="hideOnMobile">{{item[3]}}</p>
        <p class="hideOnMobile">{{item[6]}}</p>
                
    </tg-button-container>
    
</div>