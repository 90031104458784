<h3>
    
    <img id="switchViewModeButton" src="./assets/icons/folder.svg"
        matTooltip="{{ls.t('REFRESH', 'turbodepot/user-interface')}}"
        (click)="switchViewMode()"/>
    
    {{this.ls.t('EXPEDITIONS_GLOBAL_LIST', 'centroalum/app')}}
    
    <img id="refreshGlobalListButton" src="./assets/icons/refresh.svg"
        matTooltip="{{ls.t('REFRESH', 'turbodepot/user-interface')}}"
        (click)="loadExpeditionsGlobalList()"/>
</h3>

<button id="closeExpeditionsGlobalListButton" mat-button color="primary"
    (click)="closeDialog(0)">
    
    {{this.ls.t('CLOSE', 'turbodepot/user-interface')}}
    
</button>

<ng-container *ngIf="isBigViewMode">
 
    <div class="bigViewModeContainer">
        
        <div *ngFor="let e of expeditionsGlobalList">
        
            <h4>{{e.expedition.name}}</h4>
            
            <h5>{{ls.t('INCOMPLETE_ORDERS', 'centroalum/app') + ': ' + countExpeditionIncompleteOrders(e)}}</h5>
            
            <h6>{{ls.t('COMPLETE_ORDERS', 'centroalum/app') + ': ' + countExpeditionCompleteOrders(e)}}</h6>

        </div>
        
    </div>
       
</ng-container>


<ng-container *ngIf="!isBigViewMode">
    
    <div *ngFor="let e of expeditionsGlobalList">

        <h4>{{e.expedition.name}}</h4>
        
        <div class="routeDetailsContainer">
            
           <ng-container *ngFor="let customerData of e.orders.orders | keyvalue">
            
                <div class="tableContainer" *ngIf="customerData.value.orders.length > 0">
                    
                    <h5>{{ls.t('CUSTOMER', 'turbodepot/shopping') + ': ' + customerData.key + ' - ' + customerData.value.name}}</h5>
                    
                    <div class="headerContainer">
                        <h6>{{ls.t('ORDER_NUMBER', 'turbodepot/shopping')}}</h6>
                        <h6>{{ls.t('ORDER_DATE', 'turbodepot/shopping')}}</h6>
                        <h6>{{ls.t('DELIVERY_DATE', 'centroalum/app')}}</h6>
                        <h6 class="hideOnMobile">{{ls.t('ESTIMATE_DATE', 'centroalum/app')}}</h6>
                        <h6 class="hideOnMobile">{{ls.t('OBSERVATIONS', 'turbodepot/user-interface')}}</h6>
                    </div>
                    
                    <tg-button-container *ngFor="let item of customerData.value.orders; let i = index"
                        id="ordersListItem_{{i}}"
                        [releaseOnMouseUp]="false"
                        [defaultOpacity]=".8"
                        [hoverOpacity]="1"
                        [clickScale]="1"
                        [class.hasPendingToReceive]="item[4] === true"
                        (click)="expansionRoutesViewService.loadOrderData(item[0], customerData.key, customerData.value.name, item[6])">
                        
                        <p>{{item[0]}}</p>
                        <p>{{item[1]}}</p>
                        <p>{{item[2]}}</p>
                        <p>{{item[3]}}</p>
                        <p>{{item[6]}}</p>
                                
                    </tg-button-container>
                    
                </div>
            
           </ng-container>
                        
        </div>
        
    </div>
    
</ng-container>