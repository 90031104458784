import { Routes } from '@angular/router';
import { HomeViewComponent } from '../../view/views/home-view/home-view.component';
import { ProductsViewComponent } from 'src/main/view/views/products-view/products-view.component';
import { CatalogsViewComponent } from 'src/main/view/views/catalogs-view/catalogs-view.component';
import { OrdersMenuViewComponent } from 'src/main/view/views/orders-menu-view/orders-menu-view.component';
import { OrdersNewViewComponent } from 'src/main/view/views/orders-new-order-view/orders-new-order-view.component';
import { OrdersWebListViewComponent } from 'src/main/view/views/orders-web-list-view/orders-web-list-view.component';
import { OrdersHistoryViewComponent } from 'src/main/view/views/orders-history-view/orders-history-view.component';
import { ExpansionRoutesViewComponent } from 'src/main/view/views/expansion-routes-view/expansion-routes-view.component';
import { RoutesListComponent } from 'src/main/view/views/expansion-routes-view/routes-list.component';
import { RouteDetailsComponent } from 'src/main/view/views/expansion-routes-view/route-details.component';
import { IncidencesViewComponent } from 'src/main/view/views/incidences-view/incidences-view.component';


export const AppRoutes = {
    home: '',
    products: 'products',
    catalogs: 'catalogs',
    ordersMenu: 'orders-menu',
    ordersNew: 'orders-new',
    ordersWeblist: 'orders-web-list',
    ordersHistory: 'orders-history',
    ordersExpansionRoutes: 'orders-expansion-routes',
    ordersIncidences: 'orders-incidences',
};


export const routes: Routes = [
    
    
    // Home route
    { path: AppRoutes.home, component: HomeViewComponent, 
      data: { titleKey: 'HOME',  titleBundle: 'turbodepot/user-interface'} },
    
      
    // Products route
    { path: AppRoutes.products, component: ProductsViewComponent, 
      data: { titleKey: 'PRODUCTS',  titleBundle: 'turbodepot/shopping'} },
    
      
    // Catalogs route
    { path: AppRoutes.catalogs, component: CatalogsViewComponent, 
            data: { titleKey: 'CATALOGS',  titleBundle: 'turbodepot/shopping'} },
            
    { path: AppRoutes.catalogs + '/:id/:isDirectory', component: CatalogsViewComponent, 
      data: { titleKey: 'CATALOGS',  titleBundle: 'turbodepot/shopping'} },
    
      
    // Orders routes
    { path: AppRoutes.ordersMenu, component: OrdersMenuViewComponent, 
      data: { titleKey: 'ORDERS',  titleBundle: 'turbodepot/shopping'} },
      
    { path: AppRoutes.ordersNew, component: OrdersNewViewComponent, 
      data: { titleKey: 'NEW_ORDER',  titleBundle: 'turbodepot/shopping'} },
    
    { path: AppRoutes.ordersWeblist, component: OrdersWebListViewComponent, 
      data: { titleKey: 'WEB_ORDERS',  titleBundle: 'centroalum/app'} },
    
    { path: AppRoutes.ordersHistory, component: OrdersHistoryViewComponent, 
      data: { titleKey: 'ORDERS_HISTORY',  titleBundle: 'centroalum/app'} },
    
    { path: AppRoutes.ordersExpansionRoutes, component: ExpansionRoutesViewComponent, 
      data: { titleKey: 'EXPANSION',  titleBundle: 'centroalum/app'},
        children: [
            { path: '', component: RoutesListComponent },
            { path: ':route', component: RouteDetailsComponent }
        ]
    },
        
    { path: AppRoutes.ordersIncidences, component: IncidencesViewComponent, 
          data: { titleKey: 'INCIDENCES',  titleBundle: 'centroalum/app'} },
        
          
    // Default / not found route
    { path: '**', redirectTo: AppRoutes.home }
];
