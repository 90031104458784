import { Injectable } from '@angular/core';
import { TurboApiCallerService, DialogService, ViewService } from 'turbogui-angular';
import { OrderHistoryDetailsComponent } from './order-history-details.component';
import { ObjectUtils } from 'turbocommons-ts';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * The service for the expansion view
 */
@Injectable()
export class ExpansionRoutesViewService extends ViewService{


    /**
     * List of routes that are available
     */
    routesList: any[] = [];


    /**
     * Data for the route that is currently being edited
     */
    routeBeingCurrentlyEdited:any = {};
    
    
    get isEditingRoute(){
        
        return this.routeBeingCurrentlyEdited.hasOwnProperty('dbId');
    }


    /**
     * Initializes the route being currently edited to an empty object so it can be used to create a new route  
     */
    startCreatingNewRoute(){
        
        this.routeBeingCurrentlyEdited = {
            name: '',
            customers: ''
        };
    }


    /**
     * Initializes the route being currently edited to the current route so it can be used to edit it 
     */
    startEditingRoute(){
        
        this.routeBeingCurrentlyEdited = ObjectUtils.clone(this.routeData.routeObject);
    }
     
    
    /**
     * Data for the currently selected route
     */
    routeData:any = null;


    get isShowingRouteData(){

        return this.routeData !== null;
    }

    /**
     * Date to filter the list of route details by estimate date
     */
    routeDetailsDateFilter = '';
    
    
    /**
     * Id for the expedition that is defined to filter the route details
     */
    routeDetailsExpeditionFilter:any = null;
    
    
    /**
     * Data for the order that is being currently shown
     */
    currentOrderData:any = null;
    
    
    /**
     * ID for the order that is being currently shown
     */
    currentOrderNumber = '';
    
    
    /**
     * Comments for the order that is being currently shown
     */
    currentOrderComments = '';
    
    
    /**
     * List of expeditions that are available for the current route
     */
    expeditionsList: any[] = [];
    
    
    /**
     * Flag that tells if we are modifying an expedition
     */    
    isEditingExpedition = false;
    
    
    /**
     * Data for the expedition that is currently being edited
     */
    expeditionBeingCurrentlyEdited:any = null;
    
    
    /**
     * The code for the customer when order line data is being shown
     */
    currentCustomerCode = '';
    
    
    /**
     * The name for the customer when order line data is being shown
     */
    currentCustomerName = '';
        

    constructor(public dialogService: DialogService,
                public router: Router,
                public apiService: TurboApiCallerService) {
                    
        super();
    }
    
    
    loadAllRoutesList(){
        
        this.routesList = [];
        this.routeData = null;
                
        this.apiService.call('expansion/routes-list')
            .then(response => {
            
            this.routesList = response;
        });
    }
    
    
    /**
     * Load the details of a route that is currently defined as a parameter on the URL
     * 
     *@param routeDbId The ID of the route to load. if not defined, the current active route is used
     */
    loadRouteData(routeDbId:string|null = null){
        
        if(routeDbId === null && this.routeData?.routeObject?.dbId){
            
            routeDbId = this.routeData.routeObject.dbId;    
        }
        
        this.routeData = null;
        
        this.apiService.call('expansion/route-get-data',
            {routeId: routeDbId,
             estimateDateFilter: this.routeDetailsDateFilter,
             expeditionFilter: this.routeDetailsExpeditionFilter === null? '' : this.routeDetailsExpeditionFilter.dbId },
             {handleErrors: false})
            .then(response => {
                    
                this.routeData = response;
            
            }).catch(() => {
                this.router.navigate([`/${AppRoutes.ordersExpansionRoutes}`]);
            });
    }
    
    
    /**
     * Obtain the name of the current route
     */
    get currentRouteName(){
        
        if(this.routeData === null){
            
            return '';
        }
        
        return this.routeData.routeObject.name;
    }
    
    
    /**
     * Obtain the list of expeditions for the current route
     */
    loadExpeditions(){
        
        if(this.routeData === null){

            return;
        }
        
        this.expeditionsList = [];
                    
        this.apiService.call('expansion/expeditions-list',
            {routeId: this.routeData.routeObject.dbId})
            .then(response => {
            
            this.expeditionsList = response;
        });
    }
    
    
    /**
     * Load the details of an order as a popup 
     */
    loadOrderData(orderNumber:string, customerCode:string, customerName:string, orderComments:string) {

        this.currentOrderData = null;
        this.currentOrderNumber = orderNumber;
        this.currentOrderComments = orderComments;
        this.currentCustomerCode = customerCode;
        this.currentCustomerName = customerName;
        
        this.apiService.call('orders/get-navision-order-details',
            {orderNumber: orderNumber, "codigo-cliente": this.currentCustomerCode})
            .then(response => {
                    
            this.currentOrderData = response;
                        
            this.dialogService.addDialog(OrderHistoryDetailsComponent, {
                width: '1500px',
                modal: false,
                viewContainerRef: this.viewContainerRef});
        });
    }
}
