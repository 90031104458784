import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { View, LocalesService, BrowserService, ButtonImageComponent, ButtonContainerComponent } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { CatalogsViewService } from 'src/main/view/views/catalogs-view/catalogs-view.service';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * Catalogs view
 */
@Component({
    selector: 'app-catalogs-view',
    standalone: true,
    imports: [CommonModule, ButtonImageComponent, ButtonContainerComponent],
    providers: [CatalogsViewService],
    templateUrl: './catalogs-view.component.html',
    animations: [ContentFadeAnimationClass.getTrigger()],
    styleUrls: ['./catalogs-view.component.scss']
})


export class CatalogsViewComponent extends View implements OnInit {


    constructor(public ls: LocalesService,
                public route: ActivatedRoute,
                public router: Router,
                public catalogsViewService: CatalogsViewService,
                public browserService: BrowserService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, catalogsViewService);
    }
    
    
    isDirectory:boolean = true;
    
 
    ngOnInit() {
        
        this.route.paramMap.subscribe((params:any) => {
            
            this.isDirectory = params.get('isDirectory') !== 'false';
            
            this.catalogsViewService.load(params.get('id'), this.isDirectory);
        });
    }


    /**
     * Process the catalog items click
     */
    onCatalogItemClick(item: any) {

        this.router.navigate(['/' + AppRoutes.catalogs + '/' + item.id + '/' + item.isDirectory]);
    }
}
