import { Component, AfterViewInit, ViewContainerRef } from '@angular/core';
import { View, LocalesService, DialogService } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { OrdersWebListViewService } from './orders-web-list-view.service';
import { CommonModule } from '@angular/common';


/**
 * Orders list view
 */
@Component({
      selector: 'app-orders-web-list-view',
      standalone: true,
    imports: [CommonModule],
    providers: [OrdersWebListViewService],
      templateUrl: './orders-web-list-view.component.html',
      animations: [ContentFadeAnimationClass.getTrigger()],
      styleUrls: ['./orders-web-list-view.component.scss']
})


export class OrdersWebListViewComponent extends View implements AfterViewInit {


    constructor(public ls: LocalesService,
                public dialogService: DialogService,
                public ordersListViewService: OrdersWebListViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, ordersListViewService);
    }


    ngAfterViewInit() {

        this.ordersListViewService.loadList();
    }
    
    
    /**
     * Obtain the data from a single order
     */
    onOrderClick(item: string[]) {

        this.ordersListViewService.loadOrderData(item[0]);
    }
}
