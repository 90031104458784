<tg-button-container class="routeContainer" 
    [releaseOnMouseUp]="false"
    [defaultOpacity]=".7"
    [hoverOpacity]=".8"
    [clickOpacity]="1"
    [clickScale]="1.03"
    *ngFor="let item of expansionRoutesViewService.routesList; let i = index"
    (click)="showRouteDetails(item)">
    
    <img src="./assets/icons/truck.svg" />  
    <h3>{{item.name}}</h3>
    <p>{{item.customers}}</p>
    
</tg-button-container>




