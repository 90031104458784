import { Injectable } from '@angular/core';
import { TurboApiCallerService, DialogService, LocalesService, ViewService } from 'turbogui-angular';
import { UserModel } from 'src/main/model/models/user.model';
import { StringUtils } from 'turbocommons-ts';
import { OrderHistoryDetailsComponent } from './order-history-details.component';
import { CustomerService } from '../../../controller/customer.service';


/**
 * The service for the orders list view
 */
@Injectable()
export class OrdersHistoryViewService extends ViewService {
    
    
    /** Stores the computed title for the view */
    viewTitle = '';
    
    
    /** The customer code for the one we will be listing the order history */
    selectedCustomer = '';
    
    
    /** The customer name for the one we will be listing the order history */
    selectedCustomerNombre = '';
    
    
    /** List of order items that have been loaded for the current user */
    itemsList: any[] = [];
    
    
    /** Data for the order which details are being currently shown */
    currentOrderData: any = null;

    
    /** The customer code for the order that is being currently shown */
    currentOrderCustomerCode: string = '';
    
    
    /** Whether the list of orders is for expired estimates */
    isEstimateLapsed = false;
        

    constructor(public dialogService: DialogService,
                public ls: LocalesService,
                public customerService: CustomerService,
                public userModel: UserModel,
                public apiService: TurboApiCallerService) {
    
        super();
        this.computeViewTitle();
    }
    
    
    /**
     * Compute the title for the view
     */
    private computeViewTitle() {
        
        let title = this.ls.t('ORDERS_LIST', 'turbodepot/shopping');
        
        if(StringUtils.isEmpty(this.selectedCustomerNombre)){
        
            if(this.itemsList.length > 0){
            
                if(this.isEstimateLapsed){
                    
                    title += ' - ' + this.ls.t('ORDERS_EXPIRED', 'turbodepot/shopping');
                
                }else{
                    
                    title += ' - ' + this.ls.t('ORDERS_OUT_OF_TIME', 'turbodepot/shopping');
                }          
            }
            
        }else{
            
            title += ' - ' + this.selectedCustomerNombre; 
        }
        
        this.viewTitle = title;
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadList() {

        this.itemsList = [];
        
        this.apiService.call('orders/list-navision-orders', {"codigo-cliente": this.selectedCustomer})
            .then(response => {
            
            this.itemsList = response;
            
            this.computeViewTitle();
        });
    }
    
    
    /**
     * Search for a customer to get the list 
     */
    selectCustomer() {
        
        this.selectedCustomer = '';
        this.selectedCustomerNombre = '';

        this.customerService.selectCustomer(undefined, (codigo, nombre) => {
                        
            if(codigo != ''){
                
                this.selectedCustomer = codigo;
                this.selectedCustomerNombre = nombre;
                this.loadList();
            }
        });
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadOrderData(orderNumber:string, customerCode:string) {

        this.currentOrderData = null;
        this.currentOrderCustomerCode = '';
        
        this.apiService.call('orders/get-navision-order-details',
            {orderNumber: orderNumber, "codigo-cliente": customerCode})
            .then(response => {
                    
            this.currentOrderData = response;
            this.currentOrderCustomerCode = customerCode;
                        
            this.dialogService.addDialog(OrderHistoryDetailsComponent, {
                width: '1500px',
                modal: false,
                viewContainerRef: this.viewContainerRef});
        });
    }
    
    
    /**
     * Find all the orders that are out of time 
     */
    async showOutOfTimeOrders() {
        
        this.itemsList = [];
        this.selectedCustomer = '';
        this.selectedCustomerNombre = '';
        this.isEstimateLapsed = false;

        this.itemsList = await this.apiService.call('orders/list-navision-orders', {"codigo-cliente": '*'});
        
        this.computeViewTitle();
    }
    
    
    async showExpiredEstimateOrders() {
        
        this.itemsList = [];
        this.selectedCustomer = '';
        this.selectedCustomerNombre = '';
        this.isEstimateLapsed = true;

        this.itemsList = await this.apiService.call('orders/list-navision-orders',
            {"codigo-cliente": '*', 'isEstimateLapsed': true});
        
        this.computeViewTitle();
    }
}
