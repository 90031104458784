import { Component, ElementRef, Inject } from '@angular/core';
import { LocalesService, DialogBaseComponent } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersNewOrderViewService } from '../orders-new-order-view/orders-new-order-view.service';
import { OrdersWebListViewService } from './orders-web-list-view.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';


/**
 * Component to show the details of an order
 */
@Component({
    selector: 'app-order-details',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    providers: [OrdersNewOrderViewService],
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss']
})


export class OrderDetailsComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "OrderDetailsComponent";
    

    constructor(public elementRef: ElementRef,
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                public ordersListViewService: OrdersWebListViewService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
    }
}
