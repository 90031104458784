import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DialogService, LocalesService, FadeAnimationClass, BrowserService, DialogTwoOptionComponent, NotificationService, TurboApiCallerService } from 'turbogui-angular';
import { UserModel } from 'src/main/model/models/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * The main component that contains all the application elements
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [FadeAnimationClass.getTrigger('mediumFade', '2s ease', '600ms ease')],
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

    
    currentRoute = '';
    

    constructor(public router: Router,
                public route: ActivatedRoute,
                public dialogService: DialogService,
                public ls: LocalesService,
                public apiService: TurboApiCallerService,
                public userModel:UserModel,
                public browserService: BrowserService,
                private titleService: Title,
                public notificationService: NotificationService) {
    }
    
    
    ngOnInit() {
    
        // Subscribe to the router events to get the current route
        // and set the browser tab title based on the localization key and bundle assigned to the route
        // at the route definition via the data titleKey and titleBundle properties
        this.router.events.subscribe(() => {
        
            this.currentRoute = this.router.url;
            
            // Obtaion the current route title and set it to the browser tab title
            let route = this.router.routerState.root;
            
            while (route.firstChild) {
                
                route = route.firstChild;
            }
            
            const data = route.snapshot.data;
            
            if (data['titleKey'] && data['titleBundle']) {
                
                const translatedTitle = this.ls.t(data['titleKey'], data['titleBundle']);
                this.titleService.setTitle(`Centroalum - ${translatedTitle}`);
            }
        });
    }
    
    
    openHomeRoute(){
        
        this.router.navigate(['/' + AppRoutes.home]);
    }
    
    
    openProductsRoute(){
        
        this.router.navigate(['/' + AppRoutes.products]);
    }
    
    
    isProductsSelected(){
        
        return this.currentRoute === '/' + AppRoutes.products;
    }
    
    
    openCatalogsRoute(){
        
        this.router.navigate(['/' + AppRoutes.catalogs]);
    }
    
    
    isCatalogsSelected(){
        
        return this.currentRoute === '/' + AppRoutes.catalogs;
    }
    
    openOrdersMenuRoute(){
        
        this.router.navigate(['/' + AppRoutes.ordersMenu]);
    }
    
    
    isOrdersSelected(){
        
        return this.currentRoute === '/' + AppRoutes.ordersMenu;
    }
    
    
    logout(){
        
        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.t('CLOSE_SESSION', 'turbodepot/users') + '?', this.ls.t('PLEASE_CONFIRM_TO_CLOSE_SESSION', 'turbodepot/users')],
                options: [this.ls.t('CLOSE_SESSION', 'turbodepot/users'), this.ls.t('CANCEL', 'turbodepot/user-interface')]
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    this.apiService.logout().then(() => {
                        
                        // Wait one second before redirecting to the home view to allow the logout to finish visually
                        setTimeout(() => {
                            
                            this.router.navigate(['/' + AppRoutes.home]);
                            
                        }, 1000);
                    });
                }
            });
    }
}
