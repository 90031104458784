import { Component, ElementRef } from '@angular/core';
import { LocalesService, TurboGuiAngularModule, ButtonContainerComponent} from 'turbogui-angular';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { ActivatedRoute } from '@angular/router';


/**
 * Route details component
 */
@Component({
    selector: 'app-route-details',
    standalone: true,
    imports: [CommonModule, MatInputModule, ButtonContainerComponent, MatButtonModule, TurboGuiAngularModule],
    providers: [],
    templateUrl: './route-details.component.html',
    styleUrls: ['./route-details.component.scss']
})


export class RouteDetailsComponent{
    
    
    constructor(public elementRef: ElementRef,
                public ls: LocalesService,
                private readonly route: ActivatedRoute,
                public expansionRoutesViewService: ExpansionRoutesViewService) {
                    
        let routeDbId = this.route.snapshot.paramMap.get('route');
        
        if(routeDbId !== null){
            
            this.expansionRoutesViewService.loadRouteData(routeDbId);
        }
                
    }


    clearExpeditionFilter(){

        this.expansionRoutesViewService.routeDetailsExpeditionFilter = null;
        this.expansionRoutesViewService.loadRouteData(); 
    }


    clearDateFilter(){

        this.expansionRoutesViewService.routeDetailsDateFilter = "";
        this.expansionRoutesViewService.loadRouteData(); 
    }
    
}
