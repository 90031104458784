
<h2>{{this.ls.t('INCIDENCES', 'centroalum/app')}}</h2>
 
<div class="buttonsContainer">

    <app-select-customer-button id="selectCustomerButton"
            (customerCleared)="onCustomerCleared()"
            (customerConfirmed)="onCustomerSelected($event)" />  
                
    <tg-button-container id="newIncidenceButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        [disabledOpacity]=".7"
        (click)="onCustomerCleared()">
        
        <h3>{{ls.t('NEW_INCIDENCE', 'centroalum/app')}}</h3>
            
    </tg-button-container>
    
    <tg-button-container id="deleteIncidenceButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        [disabledOpacity]=".7"
        (click)="onCustomerCleared()">

        <h3>{{ls.t('DELETE_INCIDENCE', 'centroalum/app')}}</h3>
            
    </tg-button-container>

</div>

<!--<div class="linesContainer" [@fadeIn]-->
<!--    *ngIf="true">-->
    
<!--    <h3 class="linesTitle">{{ls.t('INCIDENCE_LINES', 'centroalum/app')}}</h3>-->
    
<!--</div>-->