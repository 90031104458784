import { Injectable } from '@angular/core';
import { SingletoneStrictClass, DialogService, TurboApiCallerService, DialogSingleOptionComponent, DialogErrorComponent, LocalesService } from 'turbogui-angular';
import { ConfigurationModel } from 'src/main/model/models/configuration.model';
import { BusyStateComponent } from 'src/main/view/components/busy-state/busy-state.component';
import { Router } from '@angular/router';


/**
 * Service that is execute before the application starts.
 * It is used to load setups, locales, and any other resources that must be avilable
 * at the very first begining of the application
 */
@Injectable({
  providedIn: 'root',
})
export class AppInitializerService extends SingletoneStrictClass {


    constructor(private readonly ls: LocalesService,
                private readonly router: Router,
                private readonly apiService: TurboApiCallerService,
                private readonly dialogService: DialogService,
                private readonly configurationModel: ConfigurationModel) {

        super(AppInitializerService);
    }


    /**
     * This method is executed at application startup and before any other thing.
     * Angular will wait till this promise finishes before continuing with the application
     * initialization
     */
    load(): () => Promise<any> {

        return (): Promise<any> => {

            return new Promise<void>((resolve) => {

                // Override the default busy state component for the dialog service class
                this.dialogService.customBusyStateComponentClass = BusyStateComponent;

                // Initialize the turbo API caller service
                this.apiService.baseUrl = this.configurationModel.serverRootUrl;
                                
                // Perform the locales service inicialization
                // Notice that we are using My4yLjE= to make sure the browser caches the locales for each new app version
                // This wildcard is replaced by turbobuilder on build and release process
                this.ls.initialize(this.configurationModel.serverRootUrl + '/apitools/translations-get',
                                    this.configurationModel.locales, ['app', 'My4yLjE='])
                                        .then(() => {
                                            
                    this.checkCredentialsOnURLFragment();
                    
                    // Call the reslove method once all the initializations have finished
                    resolve();  
                });
            });
        };
    }
    
    
    /**
     * Detect values that may have been passed to the application via the url hash.
     * Several values may have been received divided by / and in the following order:
     * userName/userMail/mailVerificationHash/userPassword
     */
    private checkCredentialsOnURLFragment(){
        
        this.apiService.loadCredentialsFromURLHashFragment();
        
        this.apiService.verifyUserMailFromURLHashFragment()
            .then(response => {
                                
            // Redirect to the home view
            if(response !== undefined){
                
                this.router.navigate(['']);
            }
                                
            switch (response){

                case undefined:
                    break;
                    
                case 0:
                    this.dialogService.addDialog(DialogSingleOptionComponent, {
                        width:'400px',
                        texts: [this.ls.t('EMAIL_ACCOUNT_CORRECTLY_VERIFIED', 'centroalum/app', [this.apiService.getLastVerifiedMail()])],
                        options: [this.ls.t('CLOSE', 'turbodepot/user-interface')]
                    });
                    break;

                case 1:
                    this.dialogService.addDialog(DialogSingleOptionComponent, {
                        width:'400px',
                        texts: [this.ls.t('EMAIL_ACCOUNT_ALREADY_VERIFIED', 'centroalum/app', [this.apiService.getLastVerifiedMail()])],
                        options: [this.ls.t('CLOSE', 'turbodepot/user-interface')]
                    });
                    break;

                default:
                    this.dialogService.addDialog(DialogErrorComponent, {
                        width:'400px',
                        texts: [this.ls.t('COULD_NOT_VERIFY_EMAIL', 'centroalum/app')],
                        options: [this.ls.t('CLOSE', 'turbodepot/user-interface')]
                    });
            }
        }); 
    }
}
