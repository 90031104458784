import { Component, ElementRef, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LocalesService, DialogBaseComponent, BrowserService, DialogService, TurboGuiAngularModule, FadeAnimationClass } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Accesorio } from 'src/main/model/dtos/Accesorio';
import { WinkHausOscilo1 } from 'src/main/model/dtos/WinkHausOscilo1';
import { WinkHausOscilo2 } from 'src/main/model/dtos/WinkHausOscilo2';
import { WinkHausPract1 } from 'src/main/model/dtos/WinkHausPract1';
import { WinkHausPract2 } from 'src/main/model/dtos/WinkHausPract2';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';


/**
 * Component that allows us to select the winkhaus values
 */
@Component({
      selector: 'app-winkhaus',
      standalone: true,
    imports: [CommonModule, FormsModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatRadioModule, MatSelectModule, TurboGuiAngularModule],
    providers: [],
      templateUrl: './winkhaus.component.html',
      animations: [FadeAnimationClass.getTrigger('fadeIn')],
      styleUrls: ['./winkhaus.component.scss']
})


export class WinkhausComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "WinkhausComponent";


    accesories:Accesorio[] = []; 
    
    
    constructor(public elementRef: ElementRef,
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public browserService: BrowserService,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                public dialogService: DialogService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
        
        this.ordersNewOrderViewService.winkHausTipology = null;
        this.ordersNewOrderViewService.winkHausLeftHand = null;
        this.ordersNewOrderViewService.winkHausFinish = '';
        this.ordersNewOrderViewService.winkHausHeight = '';
        this.ordersNewOrderViewService.winkHausWidth = '';
    }
    
    
    isTipoSelected(){
        
        return this.ordersNewOrderViewService.winkHausTipology !== null;
    }
    
    
    getItemsContainerMaxheight(){
        
        return (this.browserService.getWindowHeight() * 0.7) + 'px';
    }
    
    
    selectWinkHausOscilo1(){
        
        this.ordersNewOrderViewService.winkHausTipology = new WinkHausOscilo1();
    }
    
    
    selectWinkHausOscilo2(){
        
        this.ordersNewOrderViewService.winkHausTipology = new WinkHausOscilo2();
    }
    
    
    selectWinkHausPract1(){
        
        this.ordersNewOrderViewService.winkHausTipology = new WinkHausPract1();
    }
    
    
    selectWinkHausPract2(){
        
        this.ordersNewOrderViewService.winkHausTipology = new WinkHausPract2();
    }
    
    
    isSaveButtonEnabled(){
    
        if(this.ordersNewOrderViewService.winkHausTipology &&
           this.ordersNewOrderViewService.winkHausTipology.widthSizes.length &&
           this.ordersNewOrderViewService.winkHausWidth === ''){
            
            return false;
        }
    
        return this.ordersNewOrderViewService.winkHausLeftHand !== null &&
               this.ordersNewOrderViewService.winkHausFinish !== '' &&
               this.ordersNewOrderViewService.winkHausHeight !== '' &&
               this.ordersNewOrderViewService.orderLineBeingEdited.units > 0;
    }
    
    
    submitWinkHaus(){
        
        let units = this.ordersNewOrderViewService.orderLineBeingEdited.units;
        let productCodes = [];
        let productsToAdd = [];

        let cols = Math.max(1, (this.ordersNewOrderViewService.winkHausTipology?.widthSizes as string[]).length);
        let col = Math.max(0, (this.ordersNewOrderViewService.winkHausTipology?.widthSizes as string[]).indexOf(this.ordersNewOrderViewService.winkHausWidth));
        let row = (this.ordersNewOrderViewService.winkHausTipology?.heightSizes as string[]).indexOf(this.ordersNewOrderViewService.winkHausHeight);

        if(this.ordersNewOrderViewService.winkHausLeftHand) {

            productCodes = String((this.ordersNewOrderViewService.winkHausTipology?.leftValues as string[])[cols * row + col]).split('+');
            productsToAdd = this.ordersNewOrderViewService.winkHausTipology?.leftAdd as string[];

        } else {

            productCodes = String((this.ordersNewOrderViewService.winkHausTipology?.rightValues as string[])[cols * row + col]).split('+');
            productsToAdd = this.ordersNewOrderViewService.winkHausTipology?.rightAdd as string[];
        }

        // Add all the products that are related to the selected parameters to the current order
        let productsToRequest = [];
        
        for(let code of productCodes){
        
            productsToRequest.push(code.trim());
        }

        for(let code of productsToAdd){

            code = code.replace("XX", this.ordersNewOrderViewService.winkHausFinish);
            productsToRequest.push(code.trim());   
        }
        
        this.ordersNewOrderViewService.createOrderLinesFromProductsList(productsToRequest, units, false, () =>{
            
            this.dialogService.removeAllDialogs();
        });
    }
}
