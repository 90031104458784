import { Component, ElementRef, Inject, OnDestroy } from '@angular/core';
import { LocalesService, DialogBaseComponent, TurboApiCallerService, ButtonContainerComponent } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';


@Component({
    selector: 'app-expeditions-global-list',
    standalone: true,
    imports: [CommonModule, MatButtonModule, ButtonContainerComponent, MatTooltipModule],
    providers: [],
    templateUrl: './expeditions-global-list.component.html',
    styleUrls: ['./expeditions-global-list.component.scss']
})


export class ExpeditionsGlobalListComponent extends DialogBaseComponent implements OnDestroy{


    static readonly DIALOG_CLASS_NAME = "ExpeditionsGlobalListComponent";

    
    /**
     * Flag to indicate if the view is in big mode or not.
     * In big view mode, all the data is shown with big visuals so the screen can be seen from a distance.
     */
    isBigViewMode = true;
    
    
    /**
     * Interval id to call the refresh every hour
     */
    private readonly intervalId: any;
    

    /**
     * Global list of all expeditions available for all routes
     */
    expeditionsGlobalList: any[] = [];
        

    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                public apiService: TurboApiCallerService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
        
        // Set an interval to call the refresh every hour (3600000 milliseconds)
        this.intervalId = setInterval(() => {
        
            this.loadExpeditionsGlobalList();
            
        }, 3600000);
        
        this.loadExpeditionsGlobalList();     
    }
    
    
    switchViewMode(){
        
        this.isBigViewMode = ! this.isBigViewMode;
    }
    
    
    loadExpeditionsGlobalList(){
        
        this.expeditionsGlobalList = [];
        
        this.apiService.call('expansion/expeditions-global-list')
            .then(response => {
            
            this.expeditionsGlobalList = response;
        });
    }
    
    
    countExpeditionIncompleteOrders(expedition: any) {
        
        let count = 0;
        
        for (const orderKey of Object.keys(expedition.orders.orders)) {
            
            const customerData = expedition.orders.orders[orderKey];
            
            for (const order of customerData.orders) {
            
                if(order[4]){
                
                    count++;    
                }    
            }
        }
        
        return count; 
    }
    
    
    countExpeditionCompleteOrders(expedition: any) {
    
        let count = 0;
        
        for (const orderKey of Object.keys(expedition.orders.orders)) {
            
            const customerData = expedition.orders.orders[orderKey];
            
            for (const order of customerData.orders) {
            
                if(!order[4]){
                
                    count++;    
                }    
            }
        }
        
        return count;        
       
    }
    
    
    ngOnDestroy() {
    
        // Clear the interval when the component is destroyed to prevent memory leaks
        if (this.intervalId) {
      
            clearInterval(this.intervalId);
        }
    }
}
