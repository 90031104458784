<h3>{{data.texts[0]}}</h3>

<div class="accesoriesContainer">

    <div class="accesoryContainer"
         *ngFor="let accesory of accesories; let i = index">
        
        <mat-form-field>
            
            <mat-label>{{ls.t('UNITS', 'turbodepot/shopping')}}</mat-label>
            <input type="number" matInput autoSelectTextOnFocus autoFocusOnDisplay
                autocomplete="off"
                *ngIf="i === 0" 
                [(ngModel)]="accesory.units">
        
            <input type="number" matInput autoSelectTextOnFocus
                autocomplete="off"
                *ngIf="i > 0" 
                [(ngModel)]="accesory.units">
                
        </mat-form-field>
        
        <p>{{accesory.accesorio}} - {{accesory.descripcion}} ({{accesory.units}})</p>
        
    </div>
    
</div>

<div class="buttonsContainer">   
    
    <button mat-button
        (click)="closeDialog(0)">
        
        {{this.ls.t('CANCEL', 'turbodepot/user-interface')}}
        
    </button>
    
    <button mat-raised-button color="primary" id="saveAcessoriesButton"
        (click)="saveAccesories()">
        
        {{this.ls.t('SAVE', 'turbodepot/user-interface')}}
        
    </button>
    
</div>