import { RouterModule } from '@angular/router';
import { routes } from '../models/app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { GlobalErrorService, ButtonImageComponent, ButtonContainerComponent } from 'turbogui-angular';
import { AppComponent } from '../../view/components/app/app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInitializerService } from '../../controller/appinitializer.service';
import { BusyStateComponent } from '../../view/components/busy-state/busy-state.component';
import { HomeViewComponent } from '../../view/views/home-view/home-view.component';
import { ProductsViewComponent } from '../../view/views/products-view/products-view.component';
import { CatalogsViewComponent } from '../../view/views/catalogs-view/catalogs-view.component';
import { LoginComponent } from '../../view/components/login/login.component';
import { OrdersNewViewComponent } from 'src/main/view/views/orders-new-order-view/orders-new-order-view.component';
import { OrdersWebListViewComponent } from 'src/main/view/views/orders-web-list-view/orders-web-list-view.component';
import { OrdersHistoryViewComponent } from 'src/main/view/views/orders-history-view/orders-history-view.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';


/**
 * This file contains the root application module that is launched at application startup
 * and constructs the application
 */
@NgModule({

    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSidenavModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatCheckboxModule,
        HomeViewComponent,
        OrdersWebListViewComponent,
        CatalogsViewComponent,
        OrdersNewViewComponent,
        OrdersHistoryViewComponent,
        ProductsViewComponent,
        ButtonImageComponent,
        ButtonContainerComponent
    ],

    declarations: [
        AppComponent,
        BusyStateComponent,
        LoginComponent
    ],

    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializerService: AppInitializerService) => appInitializerService.load(),
            deps: [AppInitializerService],
            multi: true
        },
        AppInitializerService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorService
        }
    ],

    bootstrap: [AppComponent]
})

export class AppModule { }
