import { Component, ViewContainerRef } from '@angular/core';
import { View, LocalesService, ButtonContainerComponent, TurboApiCallerService } from 'turbogui-angular';
import { UserModel } from 'src/main/model/models/user.model';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * Orders view
 */
@Component({
    selector: 'app-orders-menu-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent],
    providers: [],
    templateUrl: './orders-menu-view.component.html',
    animations: [],
    styleUrls: ['./orders-menu-view.component.scss']
})


export class OrdersMenuViewComponent extends View {


    constructor(public ls: LocalesService,
                public router: Router,
                public apiService: TurboApiCallerService,
                public userModel: UserModel,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, null);
    }
        
        
    createNewOrder(){
        
        this.router.navigate(['/' + AppRoutes.ordersNew]);
    }
    
        
    listWebOrders(){
        
        this.router.navigate(['/' + AppRoutes.ordersWeblist]);
    }
    
        
    listOrdersHistory(){
        
        this.router.navigate(['/' + AppRoutes.ordersHistory]);
    }
    
        
    showExpansionRoutes(){
    
        this.router.navigate(['/' + AppRoutes.ordersExpansionRoutes]);
    }
    
    loadIncidencesView(){
            
        this.router.navigate(['/' + AppRoutes.ordersIncidences]);
    }
}
