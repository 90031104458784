import { Component, ElementRef } from '@angular/core';
import { ButtonContainerComponent, LocalesService, TurboGuiAngularModule} from 'turbogui-angular';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * Route details component
 */
@Component({
    selector: 'app-routes-list',
    standalone: true,
    imports: [CommonModule, MatInputModule, ButtonContainerComponent, MatButtonModule, TurboGuiAngularModule],
    providers: [],
    templateUrl: './routes-list.component.html',
    styleUrls: ['./routes-list.component.scss']
})


export class RoutesListComponent{
    
    
    constructor(public elementRef: ElementRef,
                public ls: LocalesService,
                public router: Router,
                public expansionRoutesViewService: ExpansionRoutesViewService) {
                    
        this.expansionRoutesViewService.loadAllRoutesList();
    }
    
    showRouteDetails(item:any){
             
        this.router.navigate([`/${AppRoutes.ordersExpansionRoutes}/${item.dbId}`]);
    }

}
