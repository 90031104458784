import { Injectable } from '@angular/core';
import { FadeAnimationClass } from 'turbogui-angular';


/**
 * Fade animation for raw content
 */
@Injectable()
export class ContentFadeAnimationClass {

    /**
     * Get the content fade animation trigger
     */
    static getTrigger() {

        return FadeAnimationClass.getTrigger('contentFade', '500ms ease', '0ms ease');
    }
}
