import { Component, AfterViewInit, ViewContainerRef } from '@angular/core';
import { View, LocalesService, DialogService, TurboGuiAngularModule, ButtonContainerComponent, TurboApiCallerService } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { OrdersHistoryViewService } from './orders-history-view.service';
import { UserModel } from 'src/main/model/models/user.model';
import { CommonModule } from '@angular/common';


/**
 * Orders list view
 */
@Component({
    selector: 'app-orders-history-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent, TurboGuiAngularModule],
    providers: [OrdersHistoryViewService],
    templateUrl: './orders-history-view.component.html',
    animations: [ContentFadeAnimationClass.getTrigger()],
    styleUrls: ['./orders-history-view.component.scss']
})


export class OrdersHistoryViewComponent extends View implements AfterViewInit {


    constructor(public ls: LocalesService,
                public dialogService: DialogService,
                public userModel: UserModel,
                public apiService: TurboApiCallerService,
                public ordersHistoryViewService: OrdersHistoryViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, ordersHistoryViewService);
    }


    ngAfterViewInit() {

        if(this.userModel.isCustomer){
            
            this.ordersHistoryViewService.selectedCustomer = this.userModel.customer.codigo;
            this.ordersHistoryViewService.selectedCustomerNombre = this.userModel.customer.nombre;
            this.ordersHistoryViewService.loadList();
        }
    }
         
    
    /**
     * Obtain the data from a single order
     */
    onOrderClick(item: string[]) {

        this.ordersHistoryViewService.loadOrderData(item[0], item[4]);
    }
}
