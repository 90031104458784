import { Component, Inject, ElementRef } from '@angular/core';
import { LocalesService, DialogBaseComponent, TurboGuiAngularModule, TurboApiCallerService} from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { StringUtils } from 'turbocommons-ts';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/main/model/models/app.routes';


/**
 * Edit route component
 */
@Component({
    selector: 'app-route-edit',
    standalone: true,
    imports: [CommonModule, FormsModule, MatInputModule, MatButtonModule, TurboGuiAngularModule],
    providers: [],
    templateUrl: './route-edit.component.html',
    styleUrls: ['./route-edit.component.scss']
})


export class RouteEditComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "RouteEditComponent";
    
    
    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public router: Router,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                public apiService: TurboApiCallerService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
    }
    
    
    isSaveButtonDisabled(){
        
        return StringUtils.isEmpty(this.expansionRoutesViewService.routeBeingCurrentlyEdited.name) ||
               StringUtils.isEmpty(this.expansionRoutesViewService.routeBeingCurrentlyEdited.customers);
    }
    
    
    saveRoute(){
        
        this.apiService.call('expansion/route-save',
            {routeObject: this.expansionRoutesViewService.routeBeingCurrentlyEdited})
            .then(response => {
            
            if(response >= 0){
            
                if(this.expansionRoutesViewService.isEditingRoute){
                    
                    this.expansionRoutesViewService.loadRouteData();
                    
                }else{
                    
                    this.router.navigate([`/${AppRoutes.ordersExpansionRoutes}/${response}`]);
                }
                this.closeDialog(1);
            }
        });
    }
}
