import { Component, ElementRef, Inject } from '@angular/core';
import { LocalesService, DialogBaseComponent } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersHistoryViewService } from './orders-history-view.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';


/**
 * Component to show the details of an order
 */
@Component({
      selector: 'app-order-history-details',
      standalone: true,
    imports: [CommonModule, MatButtonModule],
    providers: [],
      templateUrl: './order-history-details.component.html',
      styleUrls: ['./order-history-details.component.scss']
})


export class OrderHistoryDetailsComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "OrderHistoryDetailsComponent";
    

    constructor(public elementRef: ElementRef,
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public ordersHistoryViewService: OrdersHistoryViewService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    
        super(elementRef, dialogRef);               
    }
}
