<h2>{{ordersHistoryViewService.viewTitle}}</h2>

<div class="buttonsContainer">
    
    <tg-button-container id="selectCustomerButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        [disabledOpacity]=".7"
        *ngIf="!userModel.isCustomer"
        (click)="ordersHistoryViewService.selectCustomer()">
        
        <h3>{{ls.t('ORDERS_BY_CUSTOMER', 'turbodepot/shopping')}}</h3>
            
    </tg-button-container>
    
    <tg-button-container id="selectOutOfTimeOrdersButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        [disabledOpacity]=".7"
        *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS_OUT_OF_TIME)"
        (click)="ordersHistoryViewService.showOutOfTimeOrders()">
        
        <h3>{{ls.t('ORDERS_OUT_OF_TIME', 'turbodepot/shopping')}}</h3>
            
    </tg-button-container>
    
    <tg-button-container id="selectExpiredEstimateOrdersButton"
            [releaseOnMouseUp]="false"
            [defaultOpacity]=".7"
            [hoverOpacity]=".8"
            [clickOpacity]="1"
            [clickScale]="1.07"
            [disabledOpacity]=".7"
            *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS_OUT_OF_TIME)"
            (click)="ordersHistoryViewService.showExpiredEstimateOrders()">
            
            <h3>{{ls.t('ORDERS_EXPIRED', 'turbodepot/shopping')}}</h3>
                
        </tg-button-container>

</div>

<div class="ordersContainer" *ngIf="ordersHistoryViewService.itemsList.length > 0">
    
    <div>
        <h4>{{ls.t('ORDER_NUMBER', 'turbodepot/shopping')}}</h4>
        <h4>{{ls.t('ORDER_DATE', 'turbodepot/shopping')}}</h4>
        <h4>{{ls.t('DELIVERY_DATE', 'centroalum/app')}}</h4>
        <h4>{{ls.t('ESTIMATE_DATE', 'centroalum/app')}}</h4>
        <h4>{{ls.t('CUSTOMER', 'turbodepot/shopping')}}</h4>
    </div>
    
    
    <tg-button-container *ngFor="let item of ordersHistoryViewService.itemsList; let i = index"
        id="ordersListItem_{{i}}"
        [@contentFade]
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".8"
        [hoverOpacity]="1"
        [clickScale]="1.04"
        [class.isEstimateExpired]="item[5] === true"
        (click)="onOrderClick(item)">
        
        <p *ngFor="let cell of item.slice(0, -1)">{{cell}}</p>
                
    </tg-button-container>

</div>