import { Component, Output, EventEmitter, HostListener, Input, ViewContainerRef } from '@angular/core';
import { LocalesService, ButtonContainerComponent } from 'turbogui-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserModel } from 'src/main/model/models/user.model';
import { CustomerService } from 'src/main/controller/customer.service';
import { Customer } from 'src/main/model/dtos/Customer';
import { ObjectUtils } from 'turbocommons-ts';


/**
 * A button that is used to select a customer from the list of customers.
 * It will be enabled only if the current user is allowed for this kind of operation
 */
@Component({
    selector: 'app-select-customer-button',
    standalone: true,
    imports: [CommonModule, FormsModule, ButtonContainerComponent],
    providers: [],
    templateUrl: './select-customer-button.component.html',
    styleUrls: ['./select-customer-button.component.scss']
})


export class SelectCustomerButtonComponent extends ButtonContainerComponent{
    
    
    @Input() viewContainerRef:ViewContainerRef|undefined = undefined;
    

    @Output() customerCleared = new EventEmitter();
    
    
    @Output() customerConfirmed = new EventEmitter<{ customer: Customer }>();
    
    
    selectedCustomer = new Customer();
    
    
    selectedCustomerData = '';
    
    
    releaseOnMouseUp = false;
    
    
    defaultOpacity = .7;
    
    
    hoverOpacity = .8;
    
    
    clickOpacity = 1;
    
    
    clickScale = 1.07;
    
    
    disabledOpacity = .7;
    
    
    constructor(public ls: LocalesService,
                public userModel:UserModel,
                public customerService: CustomerService) {
    
        super();
    }
    
    
    /**
     * Search for a customer to the new order that will be created
     */
    @HostListener('click', ['$event'])
    onClick(): void {
        
        if(this.selectedCustomer.codigo === ''){

            this.customerService.selectCustomer(this.viewContainerRef, (codigo, nombre) => {
                            
                if(codigo != ''){
                    
                    this.selectedCustomer.codigo = codigo;
                    this.selectedCustomer.nombre = nombre;
             
                    this.showCustomerDetails();
                }
            });

        }else{
            
            this.showCustomerDetails();
        }
    }


    setCustomer(customer:Customer){
        
        this.selectedCustomer = ObjectUtils.clone(customer);
    }
    
    
    /**
     * Show the information for the currently selected customer
     */
    showCustomerDetails(){
        
        this.customerService.showCustomerDetails(this.viewContainerRef, this.selectedCustomer.codigo,
            (selection) =>{
            
            if(selection.index === 0){

                this.selectedCustomer = new Customer();
                this.customerCleared.emit();
                
                this.onClick();
                
            }else{
            
                this.customerConfirmed.emit({ customer: selection.value });
            }
        })
    }
}
